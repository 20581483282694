$prefix: "geeks-";
$prefix: $prefix;

.places {
  width: 100%;
  border: 1px solid var(--#{$prefix}gray-300);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 5px;
  background-color: var(--#{$prefix}input-bg);
  color: var(--#{$prefix}input-color);
  // border-color: var(--#{$prefix}input-border-color);

  // .react-select-container {
  //   background-color: black;
  // }
}

.pac-container {
  z-index: 1100 !important;
}
