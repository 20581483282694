//
// user.scss
// Use this to write your custom SCSS
//

.calenday {
  min-height: 8rem;
  width: 100% !important;
}

.calenday :hover {
  background-color: rgb(230, 230, 230);
}

form {
  .form-selectt {
    background-color: var(--#{$prefix}gray-800);
    border-color: var(--#{$prefix}gray-800);
    color: var(--#{$prefix}white);
    .react-select-container {
      background-color: black;
    }
  }
}

.react-select__value-container,
.react-select__menu,
.react-select__control,
.react-select__menu-list {
  //   border: none !important;
  border-radius: 5px !important;
  border-color: var(--#{$prefix}gray-300) !important;
  background-color: var(--#{$prefix}input-bg) !important;
}

.loader-bg {
  background-color: var(--$body-bg);
}
.top {
  z-index: 99999999999999999999999999999 !important;
}
